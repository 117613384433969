import { HistoryEdu, Publish, Tune } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import SignUpExploreButton from '../Home/SignUpExploreButton';

export default function Benefits() {
  return (
    <Box sx={{ mt: 16, mb: 16 }}>
      <Typography variant="h4" component="h2" sx={{ mt: 5, mb: 3, textAlign: 'center' }}>
        Why Producers Choose Muza?
      </Typography>
      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={12} md={4}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <HistoryEdu
              sx={{
                fontSize: 80,
                color: 'primary.main',
                mb: 2
              }}
            />
            <Typography
              variant="h5"
              color="primary.main"
              align="center"
              sx={{ mt: 2 }}
              gutterBottom
            >
              Transform your talent into success
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center' }}>
              MUZA helps you to unlock the doors to major labels, exclusive opportunities, and
              connections with influential figures in the music industry.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Tune
              sx={{
                fontSize: 80,
                color: 'primary.main',
                mb: 2
              }}
            />
            <Typography
              variant="h5"
              color="primary.main"
              align="center"
              sx={{ mt: 2 }}
              gutterBottom
            >
              Step-up your productions
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center' }}>
              MUZA giving you access to a unique library of rare samples, secret stems, and
              behind-the-scenes techniques shared only by top artists. Gain insider knowledge and
              resources unavailable elsewhere.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Publish
              sx={{
                fontSize: 80,
                color: 'primary.main',
                mb: 2
              }}
            />
            <Typography
              variant="h5"
              color="primary.main"
              align="center"
              sx={{ mt: 2 }}
              gutterBottom
            >
              Say goodbye to writer’s block
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center' }}>
              MUZA feels like having hundreds of co-producers at your fingertips. Upload your
              sketches and gain invaluable musical ideas from eager producers worldwide who want to
              collaborate with you.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="center" sx={{ mt: 12 }}>
        <SignUpExploreButton text={'Start Collaborating Today!'} />
      </Stack>
    </Box>
  );
}
