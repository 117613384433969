import { Box, Container, Typography } from '@mui/material';
import SignUpExploreButton from './Home/SignUpExploreButton';
import { VideoComponent } from './Home/VideoComponent';

export default function Hero() {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: { xs: '95vh', sm: '100vh' },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundSize: 'cover',
        backgroundPosition: '60%',
        mt: -8
      }}
    >
      <VideoComponent
        srcMp4="https://muza.nyc3.digitaloceanspaces.com/muza-intro-v2.mp4"
        srcWebM="https://muza.nyc3.digitaloceanspaces.com/muza-intro-v2.webm"
        poster="/assets/muza-poster.jpg"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          width: '100%',
          height: { xs: '95vh', sm: '100vh' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: { xs: 'space-evenly', sm: 'space-evenly' },
          background: 'linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 105%)'
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 5,
              width: 680,
              maxWidth: '100%'
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 300,
                fontSize: { xs: '2.8rem', sm: '3rem', lg: '4rem' },
                lineHeight: '4rem',
                textAlign: 'left'
              }}
            >
              <Typography
                variant="h1"
                color="primary"
                sx={{ fontWeight: 300, fontSize: { xs: '2.8rem', sm: '3rem', lg: '4rem' } }}
              >
                Collaborate with your
              </Typography>
              favorite artists.
            </Typography>
            <Typography sx={{ textShadow: '0px 0px 10px #000000', maxWidth: 600 }}>
              <Box component="span" sx={{ color: '#FF5757' }}>
                Muza
              </Box>{' '}
              is a platform for musicians to collaborate with their favorite artists. Whether you're
              a new talent or a seasoned producer, we help you get inspired and turn unfinished
              sketches into masterpieces!
            </Typography>
            <SignUpExploreButton />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
