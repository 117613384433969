import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import SignUpExploreButton from './SignUpExploreButton';

export default function BenefitsSection() {
  return (
    <Container maxWidth="xl" sx={{ mt: 5, mb: 5, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <img
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              borderRadius: 1
            }}
            src="/assets/muza-artists.png"
            alt="collaborators"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ borderRadius: 1, backgroundColor: 'rgba(0, 0, 0, 0)' }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              sx={{ my: 2 }}
            >
              <Typography variant="h5" color="primary" gutterBottom>
                Launchpad for Your Career
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                Imagine you and your favorite producer collaborating on a new track. It not only
                validates your work but also opens doors to major labels and increased exposure and
                networking opportunities.
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            sx={{ my: 2 }}
          >
            <Typography variant="h5" color="primary" gutterBottom>
              A New Era of Music Collaborations
            </Typography>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              Collaborate with a diverse community of producers from around the world, each bringing
              their unique influences and styles. Gain exposure to international audiences and
              industry professionals who frequent our platform seeking fresh talent.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <SignUpExploreButton />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            }}
            src="/assets/PHONE-3D.png"
            alt="musician"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        alignItems="center"
        flexDirection="row-reverse"
        sx={{ xs: { flexDirection: 'row' } }}
      >
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            sx={{ my: 2 }}
          >
            <Typography variant="h5" color="primary" gutterBottom>
              Access to Exclusive Resources
            </Typography>
            <Typography variant="subtitle1" color="text.primary">
              Gain VIP access to unique samples and exclusive production secrets shared by your
              favorite artists. The MUZA platform is a treasure trove of sounds and techniques that
              can elevate your music to new heights.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              filter: 'brightness(0.8)',
              borderRadius: 1
            }}
            src="/assets/ableton.png"
            alt="exclusive-resources"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

// new changes:
// import { Box, Container, Grid, Paper, Typography } from '@mui/material';

// export default function BenefitsSection() {
//   return (
//     <Container maxWidth="xl" sx={{ mt: 5, mb: 5, display: 'flex', flexDirection: 'column' }}>
//       <Grid container spacing={4} alignItems="center">
//         <Grid item xs={12} md={6}>
//           <img
//             style={{
//               objectFit: 'cover',
//               width: '100%',
//               height: '100%',
//               borderRadius: 1
//             }}
//             src="/assets/muza-artists.png"
//             alt="collaborators"
//           />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Paper sx={{ borderRadius: 1, backgroundColor: 'rgba(0, 0, 0, 0)' }}>
//             <Box
//               display="flex"
//               flexDirection="column"
//               justifyContent="center"
//               alignItems="start"
//               sx={{ my: 2 }}
//             >
//               <Typography variant="h5" color="primary" gutterBottom>
//                 Launchpad for Your Career
//               </Typography>
//               <Typography variant="subtitle1" color="text.primary">
//                 Imagine you and your favorite producer collaborating on a new track. It not only
//                 validates your work but also opens doors to major labels and increased exposure and
//                 networking opportunities.
//               </Typography>
//             </Box>
//           </Paper>
//         </Grid>
//       </Grid>
//       <Grid container spacing={4} alignItems="center">
//         <Grid item xs={12} md={6}>
//           <Box
//             display="flex"
//             flexDirection="column"
//             justifyContent="center"
//             alignItems="start"
//             sx={{ my: 2 }}
//           >
//             <Typography variant="h5" color="primary" gutterBottom>
//               A New Era of Music Collaborations
//             </Typography>
//             <Typography variant="subtitle1" color="text.primary">
//               Collaborate with a diverse community of producers from around the world, each bringing
//               their unique influences and styles. Gain exposure to international audiences and
//               industry professionals who frequent our platform seeking fresh talent.
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <img
//             style={{
//               objectFit: 'cover',
//               width: '100%',
//               height: '100%'
//             }}
//             src="/assets/PHONE-3D.png"
//             alt="musician"
//           />
//         </Grid>
//       </Grid>
//       <Grid
//         container
//         spacing={4}
//         alignItems="center"
//         flexDirection="row-reverse"
//         sx={{ xs: { flexDirection: 'row' }, backgroundColor: '#FF57572f' }}
//       >
//         <Grid item xs={12} md={6}>
//           <Box
//             display="flex"
//             flexDirection="column"
//             justifyContent="center"
//             alignItems="start"
//             sx={{ my: 2 }}
//           >
//             <Typography variant="h5" color="primary" gutterBottom>
//               Access to Exclusive Resources
//             </Typography>
//             <Typography variant="subtitle1" color="text.primary">
//               Gain VIP access to unique samples and exclusive production secrets shared by your
//               favorite artists. The MUZA platform is a treasure trove of sounds and techniques that
//               can elevate your music to new heights.
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <img
//             style={{
//               objectFit: 'cover',
//               width: '100%',
//               height: '100%',
//               maxWidth: 350,
//               marginTop: "-80px",
//               filter: 'brightness(0.8)',
//               paddingBottom: '80px'
//             }}
//             src="/assets/MACBOOK.png"
//             alt="exclusive-resources"
//           />
//         </Grid>
//       </Grid>
//       <Grid
//         container
//         spacing={4}
//         alignItems="center"
//         flexDirection="row-reverse"
//         sx={{
//           xs: { flexDirection: 'row' },
//           backgroundImage: 'url(/assets/musicians.png)',
//           backgroundPosition: 'bottom',
//           backgroundSize: 'cover',
//           position: 'relative',
//         }}
//       >
//         <Grid item xs={12} md={12}>
//           <Box
//             display="flex"
//             flexDirection="column"
//             justifyContent="center"
//             alignItems="center"
//             sx={{ my: 2, zIndex: 3, position: 'relative' }}
//           >
//             <Typography variant="h5" color="primary" gutterBottom>
//               Boost your music carreer
//             </Typography>
//             <Typography variant="subtitle1" color="text.primary">
//               MUZA ambassadors released music on some of the biggest record labels.
//             </Typography>
//           </Box>
//           <Box sx={{
//             position: 'absolute',
//             top: 0,
//             bottom: 0,
//             left: 0,
//             right: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: '#74057abf',
//             zIndex: 0
//           }}></Box>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// }
