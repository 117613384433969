import { Box, Typography } from '@mui/material';

export default function Testimonial({
  name,
  text,
  subtitle,
  thumbnail
}: {
  name: string;
  text: string;
  subtitle: string;
  thumbnail: string;
}) {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <img
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          filter: 'brightness(0.8)',
          aspectRatio: 1,
          borderRadius: 12,
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)'
        }}
        src={thumbnail}
        alt={name}
      />
      <Typography variant="h6" color="text.primary" sx={{ lineHeight: '1.5rem' }}>
        {name}
      </Typography>
      <Typography variant="h6" color="primary" sx={{ lineHeight: '1.2rem', fontSize: 16 }}>
        {subtitle}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" sx={{ fontStyle: 'italic' }}>
        "{text}"
      </Typography>
    </Box>
  );
}
