import { CSSProperties, useCallback, useEffect, useRef } from 'react';
import { useIsVisible } from '../../hooks/useIsVisible';

type VideoComponentProps = {
  srcMp4: string;
  srcWebM?: string;
  poster?: string;
  alt?: string;
  style?: CSSProperties;
};

export const VideoComponent = ({ srcMp4, srcWebM, poster, style, alt }: VideoComponentProps) => {
  const { isVisible, targetRef } = useIsVisible(
    {
      root: null,
      rootMargin: '200px',
      threshold: 0.1
    },
    false
  );

  const videoRef = useRef<HTMLVideoElement>(null!);

  const startVideoOnMouseMove = useCallback(async () => {
    try {
      await videoRef.current.play();
    } catch (e) {
      // do nothing
    }
  }, []);

  const stopVideoOnMove = useCallback(() => {
    try {
      videoRef.current.pause();
    } catch (e) {
      // do nothing
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      startVideoOnMouseMove();
    } else {
      stopVideoOnMove();
    }
  }, [isVisible, startVideoOnMouseMove, stopVideoOnMove]);

  return (
    <span
      ref={targetRef as any}
      style={{
        minHeight: '50px',
        height: '100%'
      }}
    >
      <video
        ref={videoRef}
        loop
        muted
        autoPlay={false}
        preload="none"
        playsInline
        poster={poster}
        aria-label={alt}
        style={{
          objectFit: 'contain',
          display: 'block',
          width: '100%',
          height: '100%',
          ...style
        }}
      >
        {srcWebM && <source src={srcWebM} type="video/webm" />}
        <source src={srcMp4} type="video/mp4" />
        Your browser does not support the video tag. Please try viewing this page in a modern browser.
      </video>
    </span>
  );
};
