import { Box, Container, Grid, Typography } from '@mui/material';
import FeaturedTracks from '../components/FeaturedTracks';
import FeaturedArtists from '../components/FeaturedArtists';
import MostRecentTracks from '../components/MostRecentTracks';
import MostDownloadedTracks from '../components/MostDownloadedTracks';
import Hero from '../components/Hero';
import BenefitsSection from '../components/Home/BenefitsSection';
import WhatIndustryThinkSection from '../components/Home/WhatIndustryThinkSection';
import CallToActionSection from '../components/CallToActionSection';
import { CentralizedAudio } from '../components/CentralizedAudio';
import InfoSection from '../components/Home/InfoSection';
import { useVerifyEmail } from '../hooks/useVerifyEmail';
import UpcomingProjects from '../components/UpcomingProjects';
import MuzaLabels from '../components/Home/MuzaLabels';
// https://500px.com/photo/1069766029/martin-garrix-by-niclas-glasberg
// https://codepen.io/ak545/pen/OeMWOJ
// https://codepen.io/aqandrew/pen/OJVNRgV

export default function Home() {
  useVerifyEmail();

  return (
    <>
      <Box
        sx={{
          // backgroundImage: `url(/stage-dark.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom center',
          color: 'white'
        }}
      >
        <Hero />
      </Box>
      <Container maxWidth="lg" id="explore" sx={{ py: 4 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" sx={{ my: 4 }}>
              <Typography variant="h4" component="span" color="primary">
                Featured
              </Typography>{' '}
              Artists
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <FeaturedTracks />
              </Grid>
              <Grid item xs={12} md={4}>
                <FeaturedArtists />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="h4" sx={{ my: 4 }}>
          <Typography variant="h4" component="span" color="primary">
            Top
          </Typography>{' '}
          Downloaded
        </Typography>
        <MostDownloadedTracks />
        <Typography variant="h4" sx={{ my: 4 }}>
          <Typography variant="h4" component="span" color="primary">
            Latest
          </Typography>{' '}
          Projects
        </Typography>
        <MostRecentTracks />
        <Typography variant="h4" sx={{ my: 4 }}>
          <Typography variant="h4" component="span" color="primary">
            Upcoming
          </Typography>{' '}
          Projects
        </Typography>
        <UpcomingProjects />
      </Container>
      <InfoSection />
      <Container maxWidth="lg" id="benefits">
        <BenefitsSection />
      </Container>
      <MuzaLabels />
      <Container maxWidth="lg" id="feedback">
        <Typography
          variant="h4"
          sx={{
            mt: 5,
            mb: 8,
            textAlign: 'center',
            verticalAlign: 'middle'
          }}
        >
          <Typography variant="h4" component="span" color="primary">
            Some feedback
          </Typography>{' '}
          from the pro’s
        </Typography>
        <WhatIndustryThinkSection />
      </Container>
      <CallToActionSection />
      <CentralizedAudio />
    </>
  );
}
