import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Box, IconButton, TablePagination, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';

import {
  ApiError,
  TrackDownloadResponse,
  TrackDownloadsService,
  TrackListResponse,
  TracksService
} from '../../client';
import { Loader } from '../Loader';
import { AudioWavePlayer } from '../AudioWavePlayer';
import { useParams } from 'react-router-dom';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { useAuth } from '../../context/AuthProvider';
import { useAudioWavePlayer } from '../../context/AudioWavePlayerContext';
import { useProfile } from '../../hooks/useProfile';
import SocialPopup from '../SocialPopup';
import slugify from 'react-slugify';
import TrackRow from '../Track/TrackRow';

const ROWS_PER_PAGE = 100;

export default function ArtistTracksList() {
  const { artistId } = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const { isAuthenticated } = useAuth();
  const { isPlaying: isCurrentPlaying, setIsPlaying: setIsCurrentPlaying } = useAudioWavePlayer();
  const { data: profile } = useProfile();

  const { data: tracks, isLoading } = useQuery(
    ['tracks', page, rowsPerPage],
    () =>
      TracksService.getArtistTracksApiTracksArtistArtistIdTracksGet(
        Number(artistId),
        page,
        rowsPerPage
      ),
    {
      keepPreviousData: true
    }
  );

  const { createSnackBar } = useSnackBarContext();

  const downloadMutation = useMutation<TrackDownloadResponse, ApiError, number>(
    (requestBody) => TrackDownloadsService.createDownloadTrackApiTrackDownloadsPost(requestBody),
    {
      onSuccess: (response) => {
        createSnackBar({ content: 'Added to Downloads', autoHide: true, severity: 'success' });
      },
      onError: async (error: ApiError) => {
        if (error.status < 500) {
          createSnackBar({
            content: error.body.detail,
            autoHide: true,
            severity: 'info'
          });
        } else {
          createSnackBar({
            content: 'An error occurred. Please try again later.',
            autoHide: true,
            severity: 'error'
          });
        }
      }
    }
  );

  if (isLoading) return <Loader />;

  if (!tracks || tracks.items.length === 0) return <div>No tracks</div>;

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page + 1);
  };

  const handleOnRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const sendToClipboard = (track: TrackListResponse) => {
    const trackDetailsUrl = `${window.location.origin}/track/${track.id}/${slugify(track.name)}`;
    navigator.clipboard.writeText(trackDetailsUrl);
    createSnackBar({ content: 'Link copied to clipboard', autoHide: true, severity: 'info' });
  };

  const handlePlayPause = (track_id: number) => {
    if (isCurrentPlaying !== track_id) {
      setIsCurrentPlaying(track_id);
    }
  };

  const hasAccessToPremiumArtists =
    isAuthenticated && profile?.permissions?.includes('access_premium_artists');

  const isPremiumContent = (track: TrackListResponse) => track?.user?.account_type === 3;

  const handleDownload = (track: TrackListResponse) => {
    if (isAuthenticated && !isPremiumContent(track)) {
      downloadMutation.mutate(track?.id);
    } else if (hasAccessToPremiumArtists && isPremiumContent(track)) {
      downloadMutation.mutate(track?.id);
    } else if (isAuthenticated && isPremiumContent(track)) {
      createSnackBar({
        content: 'Available for advanced users only. Upgrade now!',
        autoHide: true,
        severity: 'info'
      });
    } else {
      createSnackBar({
        content: 'Please sign-in to download',
        autoHide: true,
        severity: 'info'
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {tracks.items.map((track) => (
        <TrackRow track={track} key={track.id} />
      ))}
    </Box>
  );
}
