import { Link } from 'react-router-dom';

import { Avatar, Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

import { SearchResult } from '../../client';
import slugify from 'react-slugify';
import { generateCldUrl } from '../../utils';

interface ArtistRowProps {
  artist: SearchResult | undefined;
}
export default function ArtistRow({ artist }: ArtistRowProps) {
  if (!artist) return null;

  const artist_avatar_url = artist.avatar_url && generateCldUrl(artist.avatar_url || '', 'thumbnail');

  return (
    <Link
      to={`/artist/${artist.id}/${slugify(artist.name)}`}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 2,
          px: { xs: 2, sm: 4 },
          flexDirection: 'row',
          width: '100%',
          '&:hover': {
            backgroundColor: '#ffffff2f'
          }
        }}
      >
        {artist_avatar_url ? (

          <CardMedia
            component="img"
            sx={{
              width: { xs: 64, sm: 112 },
              height: { xs: 64, sm: 112 },
              ml: { xs: 0, sm: 2 },
              borderRadius: 999,
              border: '1px solid #ffffff1f'
            }}
            image={artist_avatar_url}
            alt="Artwork"
          />
        ): (
          <Avatar
            alt={artist.name}
            sx={{
              width: { xs: 64, sm: 112 },
              height: { xs: 64, sm: 112 },
              ml: { xs: 0, sm: 2 },
              borderRadius: 999,
              border: '1px solid #ffffff1f',
              color: '#fff',
              backgroundColor: '#0000003f'
            }}
          >
            {artist.name.charAt(0).toLocaleUpperCase()}
          </Avatar>
        )}
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <VerifiedIcon color="primary" sx={{ width: 18, height: 18 }} />
            <Typography
              color="#ffffff7f"
              variant="subtitle2"
              sx={{
                fontSize: { xs: '0.6rem', sm: '0.8rem' }
              }}
            >
              Verified Artist
            </Typography>
          </Box>
          <Typography
            color="text.primary"
            variant="h5"
            sx={{
              fontSize: { xs: '1rem', sm: '1.2rem' },
              fontWeight: 'bold'
            }}
          >
            {artist.name}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}
