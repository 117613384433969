import React from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import {
  Container,
  Paper,
  Grid,
  Typography,
  Box,
  IconButton,
  SvgIcon,
  Avatar
} from '@mui/material';
import { Verified, Stars } from '@mui/icons-material';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import ArtistTracksList from '../../components/Artist/ArtistTracksList';
import { TracksService } from '../../client';
import { Loader } from '../../components/Loader';
import ArtistSubmissionButton from '../../components/Artist/ArtistSubmissionButton';
import ArtistSubmissionPanel from '../../components/Artist/ArtistSubmissionPanel';
import { TrackSubmissionProvider } from '../../context/TrackSubmissionContext';
import BreadcrumbsPanel from '../../components/BreadcrumbsPanel';
import { generateCldUrl } from '../../utils';

const artistBadges: { [key: string]: React.ReactNode } = {
  free: null,
  premium: <Verified color="primary" />,
  pro: <Stars color="primary" />
};

const artistBadgeText: { [key: string]: string | null } = {
  free: null,
  premium: 'Verified Artist',
  pro: 'Pro Artist'
};

export default function ArtistDetails() {
  const { artistId } = useParams();
  const location = useLocation();
  const { data: artist, isLoading } = useQuery(`artist-${artistId}`, () =>
    TracksService.getArtistApiTracksArtistArtistIdGet(Number(artistId) || -1)
  );
  if (isLoading) return <Loader />;

  if (!artist)
    return (
      <Container maxWidth="xl" key={artistId} sx={{ mt: 3, mb: 14 }}>
        <Typography variant="h5" color="#ffffff6f" sx={{ textAlign: 'center' }} gutterBottom>
          Artist not found
        </Typography>
      </Container>
    );

  const artist_avatar_url = artist.avatar_url && generateCldUrl(artist.avatar_url || '', 'large');

  return (
    <TrackSubmissionProvider>
      <Container maxWidth="xl" key={artistId} sx={{ mt: 3, mb: 14 }}>
        <BreadcrumbsPanel
          breadcrumbs={[
            { label: 'Artists', href: '/search/artists' },
            { label: artist.profile_name, href: location.pathname }
          ]}
        />

        <Grid container alignItems="stretch">
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                overflow: 'hidden',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start'
              }}
            >
              <Avatar
                alt={artist.profile_name}
                sx={{
                  width: '100%',
                  height: '100%',
                  maxHeight: 700,
                  color: '#fff',
                  backgroundColor: '#0000003f'
                }}
                src={artist_avatar_url}
                variant="square"
              />
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  bottom: 40,
                  background: '#0000003f',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderRadius: 0,
                  gapX: 2,
                  px: 4,
                  pt: 2
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {artistBadges[artist?.plan || 'free']}
                  <Typography color="#ffffff" variant="subtitle1">
                    {artistBadgeText[artist?.plan || 'free']}
                  </Typography>
                </Box>
                <Typography
                  color="#ffffff"
                  sx={{
                    fontSize: { xs: '1.4rem', sm: '2.4rem', lg: '3.2rem' },
                    fontWeight: 'bold'
                  }}
                  gutterBottom
                >
                  {artist.profile_name}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 4,
                height: '100%',
                borderRadius: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography color="#ffffffee" variant="h4" gutterBottom>
                  About the Artist
                </Typography>
                <Typography color="#ffffff9f" variant="body1" gutterBottom>
                  {artist.about}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mt: 2
                  }}
                >
                  <Box>
                    {artist.instagram_url && (
                      <IconButton aria-label="instagram" href={artist.instagram_url}>
                        <InstagramIcon color="primary" sx={{ width: 35, height: 35 }} />
                      </IconButton>
                    )}
                    {artist.facebook_url && (
                      <IconButton aria-label="facebook" href={artist.facebook_url}>
                        <FacebookRoundedIcon color="primary" sx={{ width: 35, height: 35 }} />
                      </IconButton>
                    )}
                    {artist.spotify_url && (
                      <IconButton aria-label="spotify" href={artist.spotify_url}>
                        <SvgIcon color="primary" sx={{ width: 35, height: 35 }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75c-.5.15-1-.15-1.15-.6c-.15-.5.15-1 .6-1.15c3.55-1.05 9.4-.85 13.1 1.35c.45.25.6.85.35 1.3c-.25.35-.85.5-1.3.25m-.1 2.8c-.25.35-.7.5-1.05.25c-2.7-1.65-6.8-2.15-9.95-1.15c-.4.1-.85-.1-.95-.5c-.1-.4.1-.85.5-.95c3.65-1.1 8.15-.55 11.25 1.35c.3.15.45.65.2 1m-1.2 2.75c-.2.3-.55.4-.85.2c-2.35-1.45-5.3-1.75-8.8-.95c-.35.1-.65-.15-.75-.45c-.1-.35.15-.65.45-.75c3.8-.85 7.1-.5 9.7 1.1c.35.15.4.55.25.85M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"
                            />
                          </svg>
                        </SvgIcon>
                      </IconButton>
                    )}
                  </Box>
                  <Box>
                    {/* <IconButton aria-label="like">
                      <FavoriteBorderOutlinedIcon sx={{ width: 35, height: 35 }} />
                    </IconButton> */}
                  </Box>
                </Box>
              </Box>
              <ArtistSubmissionButton />
            </Paper>
          </Grid>
        </Grid>
        <ArtistSubmissionPanel />
        <Box>
          <Typography variant="h6" color="#ffffffee" sx={{ mt: 4 }} gutterBottom>
            Available Projects from {artist.profile_name}
          </Typography>
        </Box>
        <ArtistTracksList />
      </Container>
    </TrackSubmissionProvider>
  );
}
