import {
  Container,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  Badge,
  Box,
  Typography
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import BreadcrumbsPanel from '../components/BreadcrumbsPanel';
import FAQSection from '../components/Pricing/FAQSection';
import { useProfile } from '../hooks/useProfile';
import { useCurrentSubscription } from '../hooks/useCurrentSubscription';
import { Stars, Verified } from '@mui/icons-material';
import PlanCard, { FeatureType, PlanType } from '../components/Pricing/PlanCard';
import PricingActionButtons from '../components/Pricing/PricingActionButtons';
import FeatureRow from '../components/Pricing/FeatureRow';
import { useSnackBarContext } from '../context/SnackBarContext';
import { PaymentSuccessDialog } from '../components/Pricing/PaymentSuccessDialog';
import Benefits from '../components/Pricing/Benefits';

export const PricingBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: theme.palette.primaryTransparent.main,
    fontWeight: 'bold',
    fontSize: '0.75rem'
  }
}));

const Pricing: React.FC = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { createSnackBar } = useSnackBarContext();

  const [openPaymentSuccess, setOpenPaymentSuccess] = useState<boolean>(false);

  const [isAnnual, setIsAnnual] = useState<boolean>(false);
  const { data: profile, isLoading: isProfileLoading } = useProfile();
  const { data: subscription } = useCurrentSubscription();

  useEffect(() => {
    if (searchParams.get('payment-success')) {
      createSnackBar({
        content: 'Payment successful!',
        autoHide: true,
        severity: 'success'
      });
      setOpenPaymentSuccess(true);
      navigate('/pricing');
    }
  }, [searchParams]);

  const planInterval = subscription?.plan?.interval;

  const handleToggleChange = (_: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    if (newAlignment !== null) {
      setIsAnnual(newAlignment === 'annual');
    }
  };

  const plans: PlanType[] = [
    {
      planName: (
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            fontSize={{ xs: '0.8rem', sm: '1rem', md: '1.25rem' }}
          >
            Basic (Free)
          </Typography>
        </Box>
      ),
      monthlyPrice: '$0.00',
      annualPrice: '$0.00'
    },
    {
      planName: (
        <Box display="flex" flexDirection="row" justifyContent="center" gap={1}>
          <Verified color="primary" sx={{ display: { xs: 'none', sm: 'inherit' } }} />
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            fontSize={{ xs: '0.8rem', sm: '1rem', md: '1.25rem' }}
          >
            Advanced
          </Typography>
        </Box>
      ),
      monthlyPrice: '$10.9',
      annualPrice: '$119.90',
      isPopular: true
    },
    {
      planName: (
        <Box display="flex" flexDirection="row" justifyContent="center" gap={1}>
          <Stars color="primary" sx={{ display: { xs: 'none', sm: 'inherit' } }} />
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            fontSize={{ xs: '0.8rem', sm: '1rem', md: '1.25rem' }}
          >
            PRO - Artist
          </Typography>
        </Box>
      ),
      monthlyPrice: '$18.9',
      annualPrice: '$207.00'
    }
  ];

  const features: FeatureType[] = [
    {
      optionName: 'Downloaded Projects each month',
      basic: 1,
      advanced: 6,
      pro: 'unlimited'
    },
    {
      optionName: 'Premium Artist Content',
      basic: false,
      advanced: true,
      pro: true
    },
    {
      optionName: 'Uploading Demo Submission',
      basic: false,
      advanced: true,
      pro: true
    },
    {
      optionName: 'Publish Your Music',
      basic: false,
      advanced: false,
      pro: true
    }
  ];

  const handleClose = () => {
    setOpenPaymentSuccess(false);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 10 }}>
      <BreadcrumbsPanel breadcrumbs={[{ label: 'Subscription Plans', href: location.pathname }]} />

      <Typography variant="h4" component="h1" sx={{ mb: 3, textAlign: 'center' }}>
        Choose Your Muza Plan
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={isAnnual ? 'annual' : 'monthly'}
          exclusive
          onChange={handleToggleChange}
          sx={{ mb: 3 }}
        >
          <ToggleButton
            sx={{
              width: '100px',
              fontWeight: 'bold',
              color: 'white',
              '&.Mui-selected': {
                color: 'white',
                backgroundColor: 'primary'
              }
            }}
            value="monthly"
          >
            Monthly
          </ToggleButton>
          <PricingBadge badgeContent={'1 month free'}>
            <ToggleButton
              sx={{
                width: '100px',
                fontWeight: 'bold',
                color: 'white',
                '&.Mui-selected': {
                  color: 'white',
                  backgroundColor: 'primary'
                }
              }}
              value="annual"
            >
              Annual
            </ToggleButton>
          </PricingBadge>
        </ToggleButtonGroup>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-around" spacing={2}>
                <Grid item xs={3} sx={{ display: { xs: 'none', md: 'inherit' } }}></Grid>
                {plans.map((plan, index) => (
                  <Grid item xs={3} key={index}>
                    <PlanCard plan={plan} isAnnual={isAnnual} isPopular={plan.isPopular} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ py: 2 }}>
              {features.map((feature, index) => (
                <FeatureRow feature={feature} key={index} />
              ))}
            </Grid>
            <Grid item xs={12}>
              <PricingActionButtons
                profile={profile}
                planInterval={planInterval}
                isAnnual={isAnnual}
                isLoading={isProfileLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Benefits />
      <FAQSection />
      <PaymentSuccessDialog open={openPaymentSuccess} handleClose={handleClose} />
    </Container>
  );
};

export default Pricing;
