import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  FormControlLabel,
  Checkbox,
  Grid,
  Link
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import MuzaLogo from '../components/MuzaLogo';

interface SignUpFormData {
  email: string;
  password: string;
  verifyPassword: string; // Add a field for password verification
  personalName: string;
  profileName: string;
  receiveEmails: boolean; // Add a field for email approval
}

export default function SignupPage() {
  const [formData, setFormData] = useState<SignUpFormData>({
    email: '',
    password: '',
    verifyPassword: '', // Initialize verifyPassword state
    personalName: '',
    profileName: '',
    receiveEmails: false // Initialize receiveEmails state
  });
  const navigate = useNavigate();
  const confirm = useConfirm();

  const signUpMutation = useMutation(
    (newUser: {
      email: string;
      password: string;
      personal_name: string;
      profile_name: string;
      receive_emails: boolean;
    }) => axios.post('/api/auth/signup', newUser),
    {
      onSuccess: () => {
        navigate('/');
      },
      onError: async (error: any) => {
        // You can handle errors here if you want to display a more specific message
        if (error.response.status === 409) {
          await confirm({
            title: 'Error',
            description: 'E-mail already exists!',
            hideCancelButton: true
          });
        }
      }
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleReceiveEmailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, receiveEmails: event.target.checked });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Check if passwords match before proceeding
    if (formData.password === formData.verifyPassword) {
      // If they match, proceed with the mutation
      signUpMutation.mutate({
        email: formData.email,
        password: formData.password,
        personal_name: formData.personalName,
        profile_name: formData.profileName,
        receive_emails: formData.receiveEmails
      });
    } else {
      // If they don't match, alert the user or handle it as you see fit
      alert('Passwords do not match!');
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: 15
      }}
    >
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
        >
          Sign up to <MuzaLogo />
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="personalName"
            label="Full Name"
            name="personalName"
            autoComplete="personalName"
            onChange={handleChange}
            value={formData.personalName}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="profileName"
            label="Profile Name"
            name="profileName"
            autoComplete="profileName"
            onChange={handleChange}
            value={formData.profileName}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={handleChange}
            value={formData.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.password}
          />
          <TextField // Add a text field for verifying the password
            margin="normal"
            required
            fullWidth
            name="verifyPassword"
            label="Verify Password"
            type="password"
            id="verifyPassword"
            onChange={handleChange}
            value={formData.verifyPassword}
          />
          <FormControlLabel
            sx={{
              userSelect: 'none'
            }}
            control={
              <Checkbox
                name="receiveEmails"
                checked={formData.receiveEmails}
                onChange={handleReceiveEmailsChange}
                color="primary"
              />
            }
            label="I agree to receive emails from Muza"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} size="large">
            Sign Up
          </Button>
          {signUpMutation.isError && (
            <Typography color="error" variant="body2">
              Error signing up. Please try again.
            </Typography>
          )}
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle1" gutterBottom>
                Already have an account?{' '}
                <Link href={'/login'} underline="none">
                  Sign in
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
