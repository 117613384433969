import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import WavesurferPlayer from '@wavesurfer/react';
import WaveSurfer from 'wavesurfer.js';
import { Box, Grid, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

import { useAudioWavePlayer } from '../context/AudioWavePlayerContext';
import slugify from 'react-slugify';
import { generateCldUrl } from '../utils';

interface TrackRowPlayerProps {
  src: string;
  track_id: number;
  track_name: string;
  track_artist?: string;
  track_artist_id?: number;
  track_image?: string;
  size?: 'small' | 'medium' | 'large';
  handlePlayPause: (track_id: number) => void;
}
export const TrackRowPlayer = ({
  src,
  track_id,
  track_name,
  track_artist,
  track_artist_id,
  track_image,
  handlePlayPause
}: TrackRowPlayerProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { isPlaying: isCurrentPlaying } = useAudioWavePlayer();

  useEffect(() => {
    if (isCurrentPlaying !== track_id && isPlaying) {
      wavesurfer?.pause();
    }
  }, [isCurrentPlaying, wavesurfer]);

  const imgRef = useRef<HTMLImageElement>(null);

  const handleMouseOver = () => {
    if (imgRef.current) {
      imgRef.current.style.filter = 'grayscale(0%)';
    }
  };

  const handleMouseOut = () => {
    if (imgRef.current) {
      imgRef.current.style.filter = 'grayscale(100%)';
    }
  };

  const onReady = async (ws: WaveSurfer) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  const onPlayPause = () => {
    if (!wavesurfer) return;

    wavesurfer.playPause();
    handlePlayPause?.(track_id);
  };

  const onPlay = () => {
    if (!wavesurfer) return;

    if (!isPlaying) {
      wavesurfer.playPause();
      handlePlayPause?.(track_id);
    }
  };

  const cld_track_image = track_image && generateCldUrl(track_image || '', 'thumbnail');

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={2} sm={2} md={2} lg={1}>
        <Box>
          <Link
            to={`/track/${track_id}/${slugify(track_name)}`}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            {cld_track_image ? (
              <img
                ref={imgRef}
                src={cld_track_image}
                alt="track"
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  maxWidth: 86,
                  maxHeight: 86,
                  filter: 'grayscale(100%)',
                  transition: 'filter 0.3s ease',
                  borderRadius: 3,
                  aspectRatio: '1/1'
                }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              />
            ) : (
              <AudioFileIcon
                sx={{
                  width: '100%',
                  height: '100%',
                  color: '#ffffff9f'
                }}
              />
            )}
          </Link>
        </Box>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={1} justifyContent="center">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <IconButton
            aria-label={isPlaying ? 'pause' : 'play'}
            onClick={onPlayPause}
            sx={{ width: 'fit-content' }}
          >
            {isPlaying ? (
              <PauseCircleIcon sx={{ fontSize: { xs: '3rem', sm: '3.4rem' } }} />
            ) : (
              <PlayCircleIcon sx={{ fontSize: { xs: '3rem', sm: '3.4rem' } }} />
            )}
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={8} sm={7} md={7} lg={8}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontSize: { xs: '0.9rem', sm: '0.9rem' } }}>
            <Link
              to={`/artist/${track_artist_id}/${slugify(track_artist)}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {track_artist}
            </Link>{' '}
            –{' '}
            <Link
              to={`/track/${track_id}/${slugify(track_name)}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {track_name}
            </Link>
          </Typography>
          <WavesurferPlayer
            key={track_id}
            height={46}
            waveColor="#B1A8AB"
            progressColor="#FF5757"
            url={src}
            onReady={async (ws) => await onReady(ws)}
            onClick={onPlay}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            barWidth={4}
            barGap={4}
            barRadius={6}
            backend="WebAudio"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
