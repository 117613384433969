import { Box, Grid, Typography } from '@mui/material';
import Testimonial from './Testimonial';

export default function WhatIndustryThinkSection() {
  return (
    <Grid container spacing={4} sx={{ mb: 16 }}>
      <Grid item xs={6} md={3}>
        <Testimonial
          name="Erez Eisen"
          subtitle="Infected Mushroom"
          text="What i love the most about MUZA is that even an unknown young producer has the
          same chance of collaboration as a well established one."
          thumbnail="/assets/artists/erez-eisen.png"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Testimonial
          name="Christian Wedekind"
          subtitle="D-Nox"
          text="Love the idea of the platform, there are so many producers I know with lots of
          unreleased stuff who are always looking to collaborate."
          thumbnail="/assets/artists/christian-wedekind.png"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Testimonial
          name="PIG&DAN"
          subtitle="Producers"
          text="We are a prolific duo who have a huge range of unfinished productions on our 
          computers. To be part of a platform like this is ideal for us to share this material 
          with the musical community and let others take it to the next level."
          thumbnail="/assets/artists/PIG&DAN.jpg"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Testimonial
          name="Mita Gami"
          subtitle="Producer"
          text="MUZA is super useful, every producer I know got lot’s of unfinished sketches on his computer"
          thumbnail="https://res.cloudinary.com/dfmabcdj7/image/upload/w_800/v1724858403/r3ecbgeyvsxg5rbverlh.jpg"
        />
      </Grid>
    </Grid>
  );
}
