import { useQuery } from 'react-query';

import 'swiper/css';
import 'swiper/css/pagination';

import { Loader } from '../Loader';
import { TracksService } from '../../client';
import { UpcomingCarousel } from './UpcomingCarousel';
import { Skeleton } from '@mui/material';

export default function UpcomingProjects() {
  const { data: upcomingTracks, isLoading } = useQuery(
    'upcoming-page-public',
    TracksService.getUpcomingTracksApiTracksUpcomingProjectsGet,
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: 10,
          minHeight: { xs: 300, md: 156 },
          width: '100%'
        }}
      />
    );
  }

  if (!upcomingTracks) {
    return null;
  }

  return <UpcomingCarousel tracks={upcomingTracks} />;
}
