import { debounce } from 'lodash';

import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Autocomplete, Box, InputBase, alpha, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { SearchService } from '../client';
import slugify from 'react-slugify';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      '&:focus': {
        width: '34ch'
      }
    }
  }
}));

export default function SearchInput() {
  const location = useLocation();
  const { searchType } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [query, setQuery] = useState(searchParams.get('q') || '');
  const [searchResults, setSearchResults] = useState<any>([]);
  const navigate = useNavigate();

  const debouncedSetQuery = debounce((newInputValue) => setQuery(newInputValue), 200);
  const {
    data: results,
    isLoading,
    isSuccess
  } = useQuery(
    ['searchResults', query, 1, 10],
    () => SearchService.searchApiSearchGet(query, '', 1, 10),
    {
      enabled: !!query
    }
  );

  useEffect(() => {
    if (results) {
      const options = results.items.map((result: any) => {
        if (result.type === 'track') {
          return { type: 'track', query: result.name, track: result };
        } else if (result.type === 'user') {
          return { type: 'user', query: result.name, user: result };
        }
      });
      setSearchResults(() => [{ type: 'search_for', query }, ...options]);
    }
  }, [results, query]);

  const getOptionLabel = (option: any) => {
    if (typeof option === 'string') {
      return option;
    }

    switch (option.type) {
      case 'search_for':
        return option.query;
      case 'user':
        return option.user.name;
      case 'track':
        return option.track.name;
      default:
        return '';
    }
  };

  const renderOption = (props: any, option: any, state: any) => {
    let label;
    let key;
    switch (option.type) {
      case 'track':
        label = option.track.name;
        key = option.track.id;
        break;
      case 'user':
        label = option.user.name;
        key = option.user.id;
        break;
      case 'search_for':
        if (!isLoading && isSuccess) {
          label = `Search for "${option.query}"`;
          key = label;
        } else {
          return null;
        }
        break;
      default:
        label = '';
    }
    return (
      <li {...props} key={key}>
        {label}
      </li>
    );
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Autocomplete
          id="free-solo-demo"
          sx={{ minWidth: '30ch' }}
          freeSolo
          options={searchResults}
          filterOptions={(options, state) => options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          onInputChange={(_, newInputValue) => {
            debouncedSetQuery(newInputValue);
            // setQuery(newInputValue);
          }}
          onChange={(event, value, reason) => {
            if (reason === 'selectOption' && value) {
              if (!value) return;
              switch (value.type) {
                case 'search_for':
                  if (searchType === 'tracks') {
                    navigate(`/search/tracks?q=${value.query}`);
                    break;
                  }
                  if (searchType === 'artists') {
                    navigate(`/search/artists?q=${value.query}`);
                    break;
                  }
                  navigate(`/search?q=${value.query}`);
                  break;
                case 'user':
                  navigate(`/artist/${value.user.id}/${slugify(value.user.name || '')}`);
                  break;
                case 'track':
                  navigate(`/track/${value.track.id}/${slugify(value.track.name)}`);
                  break;
              }
            } else if (reason === 'clear' || reason === 'createOption') {
              if (searchType === 'tracks') {
                navigate(`/search/tracks?q=${query}`);
                return;
              }
              if (searchType === 'artists') {
                navigate(`/search/tracks?q=${query}`);
                return;
              }
              navigate(`/search?q=${query}`);
              return;
            }
          }}
          renderInput={(params) => {
            const { InputLabelProps, InputProps, inputProps, ...rest } = params;
            return (
              <StyledInputBase
                {...InputProps}
                {...rest}
                placeholder="Search for artists / tracks"
                inputProps={{
                  ...inputProps,
                  'aria-label': 'search',
                  'aria-expanded': true
                }}
              />
            );
          }}
        />
      </Search>
    </Box>
  );
}
