import { useQuery } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Container, Paper, Grid, Typography, Box, Avatar } from '@mui/material';
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AudioFileIcon from '@mui/icons-material/AudioFile';

import { TracksService } from '../../client';
import { Loader } from '../../components/Loader';
import TrackRow from '../../components/Track/TrackRow';
import { generateCldUrl, removeExtension } from '../../utils';
import BreadcrumbsPanel from '../../components/BreadcrumbsPanel';
import TrackSubmissionPanel from '../../components/Track/TrackSubmissionPanel';
import TrackSubmissionButton from '../../components/Track/TrackSubmissionButton';
import { TrackSubmissionProvider } from '../../context/TrackSubmissionContext';
import slugify from 'react-slugify';

export default function TrackDetails() {
  const { trackId } = useParams();
  const location = useLocation();
  const { data: track, isLoading } = useQuery(`track-${trackId}`, () =>
    TracksService.getTrackDetailsApiTracksTrackIdGet(Number(trackId) || -1)
  );
  if (isLoading) return <Loader />;

  if (!track)
    return (
      <Container maxWidth="xl" key={trackId} sx={{ mt: 3, mb: 14 }}>
        <Typography variant="h5" color="#ffffff6f" sx={{ textAlign: 'center' }} gutterBottom>
          Track not found
        </Typography>
      </Container>
    );

  const trackNameWithoutExt = removeExtension(track.name);

  const track_avatar_url = track.image_url && generateCldUrl(track.image_url || '', 'large');

  return (
    <TrackSubmissionProvider>
      <Container maxWidth="xl" key={trackId} sx={{ mt: 3, mb: 14 }}>
        <BreadcrumbsPanel
          breadcrumbs={[
            {
              label: track.user?.profile_name || '',
              href: `/artist/${track.user?.id}/${slugify(track.user?.profile_name || '')}`
            },
            { label: trackNameWithoutExt, href: location.pathname }
          ]}
        />

        <Grid container alignItems="stretch">
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                overflow: 'hidden',
                position: 'relative',
                //   maxWidth: '700px',
                minHeight: 300,
                maxHeight: 500,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {track_avatar_url ? (
                <Avatar
                  alt="Track cover"
                  style={{
                    width: '100%',
                    height: '100%',
                    color: '#fff',
                    backgroundColor: '#0000003f'
                  }}
                  src={track_avatar_url}
                  variant="square"
                />
              ) : (
                <AudioFileIcon sx={{ width: 400, height: 400 }} />
              )}
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  bottom: 40,
                  background: '#0000003f',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderRadius: 0,
                  gapX: 2,
                  px: 4,
                  py: 1
                }}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Typography color="#ffffff" variant="h4" sx={{ textTransform: 'initial' }}>
                    {trackNameWithoutExt}
                  </Typography>
                  <Box>
                    {/* <IconButton aria-label="like" sx={{ flexShrink: 0 }}>
                      <FavoriteBorderOutlinedIcon sx={{ width: 35, height: 35 }} />
                    </IconButton> */}
                  </Box>
                </Box>
                <Link
                  to={`/artist/${track.user?.id}/${slugify(track.user?.profile_name || '')}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Typography color="#ddd" variant="h5" sx={{ textTransform: 'initial' }}>
                    {track.user?.profile_name}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 4,
                height: '100%',
                borderRadius: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography color="#ffffffee" variant="h4" gutterBottom>
                  About the Track
                </Typography>
                <Typography color="#ffffff9f" variant="body1" gutterBottom>
                  {track.about}
                </Typography>
              </Box>
              <TrackSubmissionButton />
            </Paper>
          </Grid>
        </Grid>
        <TrackSubmissionPanel />
        <Box sx={{ my: 2 }}>
          <TrackRow track={track} />
        </Box>
      </Container>
    </TrackSubmissionProvider>
  );
}
