import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';

const AccordionTitle = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const AccordionContent = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body1" color="text.secondary">
    {children}
  </Typography>
);

const faqData = [
  {
    question: 'What is MUZA?',
    answer:
      'Muza is a subscription-based music collaboration service that allows users to access a vast library of unfinished tracks and join the original producer as a collaborator. Pro users are also able to upload unfinished tracks and seek collaborators.'
  },
  {
    question: 'How does the subscription work?',
    answer:
      'Subscribing to MUZA gives you access to our project library. Our basic plan allows you to download 1 unfinished track per month with partial accessibility to our catalog, while the pro plan allows you to download 6 unfinished tracks per month from our full catalog. Pro users can also upload unfinished tracks and seek collaborators.'
  },
  {
    question: 'What devices are compatible with MUZA?',
    answer:
      'Muza is compatible with a variety of devices, including smartphones, tablets, computers, smart TVs, and more. Check our website for a complete list of supported devices.'
  },
  {
    question: 'Is there a free trial available?',
    answer:
      'Yes, Freemium subscribers are able to download one project per month from a limited number of artists without any cost.'
  },
  {
    question: 'How do I subscribe to MUZA?',
    answer:
      'To subscribe, visit our website, sign up to create an account, and choose a subscription plan that suits your needs.'
  },
  {
    question: 'What subscription plans are available?',
    answer:
      'We offer various subscription plans, including free(basic), advanced and pro plans. Each plan comes with its own set of features and pricing. Visit our pricing page for more details.'
  },
  {
    question: 'Can I cancel my subscription anytime?',
    answer:
      'Yes, you can cancel your subscription at any time. There are no long-term commitments, and you can enjoy our service on a month-to-month basis.'
  },
  {
    question: 'Can I download any project?',
    answer:
      'No, only premium users can download all the projects. Free users are limited to certain projects.'
  },
  {
    question: 'How do I start collaborating?',
    answer:
      'Once you have downloaded a track and worked on it, you can use the website to contact the artist you downloaded from and wait for feedback. If you are a premium user, you can also upload your own unfinished project.'
  },
  {
    question: 'Does it matter which DAW I use?',
    answer: 'MUZA works with WAV stems, so you can use any DAW you like.'
  },
  {
    question: 'Can I mention what I am looking for from my collaborators?',
    answer:
      'Yes, if you upload an unfinished track, you can add extra notes and mention any information you wish your potential collaborator to receive. We suggest encouraging an open mind and allowing yourself to be surprised.'
  },
  {
    question: 'How often is the music catalog updated?',
    answer:
      'Our music catalog is regularly updated with the latest releases. You can discover new music and enjoy a diverse range of genres.'
  },
  {
    question: 'Can I use Muza in multiple locations or countries?',
    answer:
      'Yes, MUZA is available in multiple countries, and you can use your subscription across different locations.'
  },
  {
    question: 'What payment methods are accepted?',
    answer:
      'We accept a variety of payment methods, including credit cards, debit cards, and other secure online payment options. Check our website for the full list of accepted payment methods.'
  },
  {
    question: 'How can I get customer support?',
    answer:
      'If you have any issues or questions, our customer support team is available to assist you. Contact us through the support section on our website.'
  }
];

const FAQSection = () => {
  return (
    <Box sx={{ mt: 8 }} display="flex" flexDirection="column">
      <Typography variant="h4" align="center" gutterBottom>
        Frequently Asked Questions
      </Typography>
      {faqData.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <AccordionTitle>{faq.question}</AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionContent>{faq.answer}</AccordionContent>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQSection;
