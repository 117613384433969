import { Badge, Box, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface PlanType {
  planName: ReactNode;
  monthlyPrice: string;
  annualPrice: string;
  isPopular?: boolean;
}

export interface FeatureType {
  optionName: string;
  basic: number | boolean | string;
  advanced: number | boolean | string;
  pro: number | boolean | string;
}

interface PlanCardProps {
  plan: PlanType;
  isAnnual: boolean;
  isPopular?: boolean;
}

export const PopularBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '0.75rem',
    marginTop: -12,
    marginRight: 40
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiBadge-badge': {
      fontSize: '0.5rem',
      marginRight: 30
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiBadge-badge': {
      fontSize: '0.37rem',
      marginRight: 20
    }
  }
}));

const PlanCard: React.FC<PlanCardProps> = ({ plan, isAnnual, isPopular }) => (
  <Box sx={{ textAlign: 'center' }}>
    <PopularBadge
      badgeContent="Most Popular!"
      invisible={!isPopular}
      anchorOrigin={
        isPopular
          ? {
              vertical: 'top',
              horizontal: 'right'
            }
          : undefined
      }
    >
      <Typography
        variant="h5"
        component="h2"
        textAlign="center"
        fontSize={{ xs: '1rem', md: '1.25rem' }}
      >
        {plan.planName}
      </Typography>
    </PopularBadge>
    <Typography
      variant="h6"
      color="text.secondary"
      textAlign="center"
      fontSize={{ xs: '0.75rem', md: '1rem' }}
    >
      {isAnnual ? `${plan.annualPrice} / yr` : `${plan.monthlyPrice} / mo`}
    </Typography>
  </Box>
);

export default PlanCard;
