import { Link } from 'react-router-dom';

import { Avatar, Box, IconButton, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import Marquee from 'react-fast-marquee';

import { TrackListResponse } from '../client';
import { usePlaylist } from '../hooks/usePlaylist';
import { useEffect, useRef, useState } from 'react';
import { generateCldUrl, removeExtension } from '../utils';
import slugify from 'react-slugify';

interface TrackBlockProps {
  track: TrackListResponse;
  hovered: number;
  onPlayPause: (track: TrackListResponse) => void;
}
export default function TrackBlock({ track, hovered, onPlayPause }: TrackBlockProps) {
  const { isPlaying, currentTrack } = usePlaylist();
  const [marqueeKey, setMarqueeKey] = useState(0);
  const [sectionIsInView, setSectionIsInView] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const marqueeBreakpoint = useMediaQuery('(max-width: 479px)');

  const isCurrentTrackPlaying = isPlaying && currentTrack?.id === track.id;
  const isTrackHovered = hovered === track.id;

  const resetMarquee = () => {
    setMarqueeKey((prevKey) => prevKey + 1);
  };

  const handleMouseLeave = () => {
    resetMarquee();
  };

  const trackNameWithoutExt = removeExtension(track.name);

  const targetRef = useRef(null);

  useEffect(() => {
    if (!marqueeBreakpoint) {
      setSectionIsInView(false);
      resetMarquee();
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setSectionIsInView(true);
        } else {
          setSectionIsInView(false);
          resetMarquee();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.9
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (observer && targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [marqueeBreakpoint]);

  const getTrackTextColor = () => {
    if (isMobile) {
      return '#ffffffcc';
    }
    if (isTrackHovered) {
      return '#ffffffee';
    }
    return '#ffffff97';
  };

  const track_image_url = track.image_url && generateCldUrl(track.image_url || '', 'thumbnail');

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px'
      }}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        ref={targetRef}
        sx={{
          backgroundColor: isTrackHovered || isMobile ? 'transparent' : 'rgba(0, 0, 0, 0.0)',
          transition: 'background-color 0.2s ease',
          zIndex: 1,
          width: '100%',
          height: '100%'
        }}
      >
        <Avatar
          variant="square"
          src={track_image_url}
          alt={track.name}
          sx={{
            width: '100%',
            height: '100%',
            aspectRatio: '1/1',
            color: '#fff',
            backgroundColor: '#0000003f',
            objectFit: 'cover'
          }}
        />
        <Paper
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            background: '#0000003f',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3,
            borderRadius: 0,
            pt: { xs: 0, md: 3 },
            pb: { xs: 0, md: 4 },
            height:
              isTrackHovered || isMobile || isCurrentTrackPlaying ? { xs: '100%', md: 110 } : 10,
            backdropFilter: isMobile ? 'blur(0px) brightness(0.8)' : 'blur(10px)',
            transition: 'height 0.2s ease',
            zIndex: 2
          }}
        >
          {(isTrackHovered || isMobile || isCurrentTrackPlaying) && (
            <IconButton
              aria-label={isCurrentTrackPlaying ? 'pause' : 'play'}
              onClick={() => onPlayPause(track)}
            >
              {isCurrentTrackPlaying ? (
                <PauseIcon sx={{ fontSize: { xs: '3rem', md: '2rem' } }} />
              ) : (
                <PlayArrowIcon sx={{ fontSize: { xs: '3rem', md: '2rem' } }} />
              )}
            </IconButton>
          )}
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              bottom: 12,
              transition: 'all 0.2s ease'
            }}
          >
            <Link
              to={`/track/${track.id}/${slugify(trackNameWithoutExt)}`}
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <Marquee
                key={marqueeKey}
                play={(isTrackHovered && !isMobile) || isCurrentTrackPlaying || sectionIsInView}
                speed={isMobile ? 100 : 90}
                delay={0.5}
                loop={0}
              >
                <Typography
                  color={getTrackTextColor()}
                  noWrap={true}
                  sx={{
                    opacity: isTrackHovered || isMobile || isCurrentTrackPlaying ? 1 : 0.8,
                    maxWidth: {
                      xs: !isCurrentTrackPlaying && !sectionIsInView ? '300px' : 'auto',
                      md: !isTrackHovered && !isCurrentTrackPlaying ? '120px' : '100%'
                    },
                    px: 1.6,
                    fontSize: { xs: '1.2rem', sm: '1rem' }
                  }}
                >
                  <b>{track?.user?.profile_name}</b> - {trackNameWithoutExt}
                </Typography>
              </Marquee>
            </Link>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
